.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.custom-placeholder::placeholder {
  color: rgba(43, 43, 43, 0.63) !important;
}
.custom-placeholder span {
  color: rgba(43, 43, 43, 0.63) !important;
}
.custom-placeholder input::placeholder {
  color: rgba(43, 43, 43, 0.63) !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  -webkit-flex: auto;
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  flex-direction: row;
  display: flex;
}


.voy-desc .ant-descriptions-bordered .ant-descriptions-item-label {
  width: 25%;
  margin-bottom: 20px;
}